<template>
    <b-overlay :show="show" spinner-variant="danger" spinner-type="grow" spinner-large rounded="sm">

        <SidebarView />
        <b-container fluid>
            <b-row>
                <b-col sm="12" md="12" lg="12">
                    <b-row>
                        <b-col sm="12" md="12" lg="12">
                            <hr>
                            <div class="d-flex justify-content-between align-Orders-center">
                                <label class="bold_text">{{ $t('all_sales') }}</label>
                            </div>
                            <hr>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12" md="4" lg="4" class="mb-2 print_hide">
                            <label>{{ $t('invoice_number') }}</label>
                            <b-input v-model="search.info" type="text" :placeholder="$t('search')"></b-input>
                        </b-col>
                        <b-col sm="12" md="4" lg="4" class="mb-2 print_hide">
                            <label>{{ $t('from_date') }}</label>
                            <b-input v-model="search.startDate" type="date" :placeholder="$t('search')"></b-input>
                        </b-col>
                        <b-col sm="12" md="4" lg="4" class="mb-2 print_hide">
                            <label>{{ $t('to_date') }}</label>
                            <b-input v-model="search.endDate" type="date" :placeholder="$t('search')"></b-input>
                        </b-col>
                        <b-col sm="12" md="2" lg="2" class="mb-2" v-for="item in Orders" v-bind:key="item.id">
                            <b-card border-variant="light" :header="item.orderCode" class="text-center">
                                <b-card-text class="order_text">{{ $t('invoice_amount') }}: <span class="bold_text">{{
                                    item.orderPrice }}</span> {{ $t('currency') }}</b-card-text>
                                <b-card-text class="order_text">{{ $t('items_count') }}: <span class="bold_text">{{
                                    item.itemsCount }}</span> {{ $t('items') }}</b-card-text>
                                <!-- <vue-barcode ref="BarImg" v-if="item.orderCode.toString()" tag="img" :value="item.orderCode.toString()"
                            :options="{ displayValue: true, lineColor: '#2B2B2C' }" /> -->
                                <p>{{ item.tags }}</p>
                                <div class="card-footer">
                                    <b-button-group>
                                        <b-button variant="danger" @click="showItemsModel(item.customerOrderItem, item)">{{
                                            $t('view_items') }}</b-button>
                                    </b-button-group>
                                </div>
                            </b-card>
                        </b-col>
                        <!-- add pagtione -->
                        <b-pagination v-model="pageNumber" :total-rows="totalOrders" :per-page="pageSize"
                            aria-controls="my-table"></b-pagination>
                    </b-row>
                </b-col>

                <b-modal id="modal-itemList" :title="$t('items')" hide-header hide-footer>
                    <p class="my-4">{{ $t('items') }}</p>
                    <div id="print" style="width: 100%; text-align: center;">
                        <img src="../assets/logoarabic.png" class="bill_logo" />
                        <p class="bill_head_text">نظام لايت كاشير</p>
                        <div class="d-flex justify-content-between">
                            <p class="bill_head_text" v-if="order">{{ formatDate(order.insertDate) }}</p>
                            <p class="bill_head_text" v-if="order">{{ userInfo.name }} </p>
                        </div>
                        <b-table striped hover :items="customerOrderItemsWithTotalPrice" :fields="customerOrderItemField"
                            responsive>
                            <template #cell(item.totalPrice)="row">
                                <span class="bold_text">({{ row.quantity }} * {{ row.sellingPrice }}) = {{ row.totalPrice
                                }}</span>
                            </template>
                        </b-table>
                        <b-card-text class="card_table d-flex justify-content-around">
                            <p>{{ $t('total') }}: {{ formattedNumber }} {{ $t('currency') }}</p>
                            <p>{{ $t('count') }}: {{ order.itemsCount }} {{ $t('items') }}</p>
                        </b-card-text>
                    </div>
                    <div class="d-flex justify-content-around">
                        <b-button variant="danger" class="w-100 m-1" @click="print()" block>{{ $t('print') }}</b-button>
                        <b-button variant="warning" class="w-100 m-1" @click="closeModel('modal-itemList')" block>{{
                            $t('close') }}</b-button>
                    </div>
                </b-modal>
            </b-row>
        </b-container>

    </b-overlay>
</template>
<script>
import SidebarView from "@/components/Layout/SidebarView.vue";
import ClockVue from "@/components/ClockVue.vue";
import VueBarcode from "@chenfengyuan/vue-barcode";
import { HTTP } from '../http/api.js';
export default {
    name: "OrdersView",
    components: {
        SidebarView,
        ClockVue,
        "vue-barcode": VueBarcode,

    },
    data() {
        return {
            show: false,
            Orders: [],
            pageNumber: 1,
            totalOrders: 0,
            pageSize: 18,
            search: {
                info: "",
                startDate: "",
                endDate: "",
            },
            totalCardOrders: 0,
            userInfo: {},
            customerOrderItem: [],

            itemId: '',
            order: '',
            totaPrice: '',
        };
    },
    computed: {
        formattedNumber() {
            return this.totaPrice.toLocaleString()
        },

        customerOrderItemField() {
            const lang = this.$i18n.locale
            if (!lang) { return [] }
            return [
                {
                    key: "item.name",
                    label: this.$i18n.t('item_name_label'),
                },
                {
                    key: "item.purchasingPrice",
                    label: this.$i18n.t('purchase_price_label'),
                },
                {
                    key: "item.sellingPrice",
                    label: this.$i18n.t('selling_price_label'),
                },
                {
                    key: "quantity",
                    label: this.$i18n.t('quantity_label'),
                },
                {
                    key: "totalPrice",
                    label: this.$i18n.t('total_label'),
                },

            ];
        },

        customerOrderItemsWithTotalPrice() {
            return this.customerOrderItem.map(item => ({
                ...item,
                totalPrice: item.quantity * item.sellingPrice,
            }));
        },
    },
    watch: {
        customerOrderItem: {
            handler() {
                this.totaPrice = 0;
                this.customerOrderItem.forEach((item) => {
                    this.totaPrice += item.sellingPrice;
                });
            },
            deep: true,
        },
        search: {
            handler() {
                this.GetAllOrders();
            },
            deep: true,
        },

        pageNumber() {
            this.GetAllOrders();
        },
    },

    mounted() {
        this.GetAllOrders();
        this.userInfo = JSON.parse(localStorage.getItem('info'));
    },

    methods: {
        formatDate(dateTime) {
            if (dateTime) {
                const [date, time] = dateTime.split("T");
                return date + " " + time.split(".")[0];
            }
            return "";
        },
        print() {
            const prtHtml = document.getElementById('print').innerHTML;
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
            }
            const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            WinPrint.document.write(`<!DOCTYPE html>
                <html>
                <head>
                    ${stylesHtml}
                </head>
                <body dir="rtl">
                    ${prtHtml}
                </body>
                </html>`);

            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.print();
            WinPrint.close();

        },

        showItemsModel(items, order) {
            this.customerOrderItem = items;
            this.order = order;
            this.$bvModal.show("modal-itemList");
        },

        getItemInfo(item) {
            this.editForm = item;
            this.$bvModal.show("modal-editItem");
        },



        closeModel(id) {
            this.$bvModal.hide(id);
        },


        GetAllOrders() {
            this.show = true;
            HTTP.get(`Admin/GetOrders?pageNumber=${this.pageNumber - 1}&pageSize=${this.pageSize}&info=${this.search.info}&startDate=${this.search.startDate}&endDate=${this.search.endDate}`)
                .then((response) => {
                    this.Orders = response.data.data.items;
                    this.totalOrders = response.data.data.totalItems;
                    this.show = false;
                })
                .catch((error) => {
                    this.show = false;
                });
        },

    },


};
</script>