<template>
  <div>
    <SidebarView />
    <b-overlay
      :show="show"
      spinner-variant="primary"
      spinner-type="border"
      rounded="sm"
    >
      <b-container fluid>
        <section class="stats-section">
          <b-row class="mb-4">
            <b-col cols="12" class="text-center">
              <h2 class="section-title">{{ $t("invoiceStatisticsLabel") }}</h2>
            </b-col>
          </b-row>
          <div class="row">
            <b-col cols="col-sm-12 col-md-3 col-lg-3" class="text-center">
              <StatCard
                color="primary"
                :value="count.newOrderCount.total"
                :label="$t('totalLabel')"
              >
              <template #icon>
                <b-icon icon="arrow-up-circle" style="font-size: 5rem;"></b-icon>
               </template>
                </StatCard>
            </b-col>
            <b-col cols="col-sm-12 col-md-3 col-lg-3" class="text-center">
              <StatCard
                color="danger"
                :value="count.newOrderCount.thisDay"
                :label="$t('todayLabel')"
              >
              <template #icon>
                <b-icon icon="arrow-up-circle" style="font-size: 5rem;"></b-icon>
               </template>
              </StatCard>
            </b-col>
            <b-col cols="col-sm-12 col-md-3 col-lg-3" class="text-center">
              <StatCard
                color="success"
                :value="count.newOrderCount.thisWeek"
                :label="$t('thisWeekLabel')"
              >
              <template #icon>
                <b-icon icon="arrow-up-circle" style="font-size: 5rem;"></b-icon>
               </template>
              </StatCard>
            </b-col>
            <b-col cols="col-sm-12 col-md-3 col-lg-3" class="text-center">
              <StatCard
                color="info"
                :value="count.newOrderCount.thisMonth"
                :label="$t('thisMonthLabel')"
              >
              <template #icon>
                <b-icon icon="arrow-up-circle" style="font-size: 5rem;"></b-icon>
               </template>
              </StatCard>

            </b-col>
          </div>
        </section>
        <section class="stats-section">
          <b-row class="mb-4">
            <b-col cols="12" class="text-center">
              <h2 class="section-title">{{ $t("itemsStatisticsLabel") }}</h2>
            </b-col>
          </b-row>
          <div class="row">
            <b-col cols="col-sm-12 col-md-3 col-lg-3" class="text-center">
              <StatCard
                color="primary"
                :value="count.newItemsOrderCount.total"
                :label="$t('totalLabel')"
              >
              <template #icon>
                <b-icon icon="arrow-up-circle" style="font-size: 5rem;"></b-icon>
               </template>
              </StatCard>
            </b-col>
            <b-col cols="col-sm-12 col-md-3 col-lg-3" class="text-center">
              <StatCard
                color="danger"
                :value="count.newItemsOrderCount.thisDay"
                :label="$t('todayLabel')"
              >
              <template #icon>
                <b-icon icon="arrow-up-circle" style="font-size: 5rem;"></b-icon>
               </template>
            </StatCard>
            </b-col>

            <b-col cols="col-sm-12 col-md-3 col-lg-3" class="text-center">
              <StatCard
                color="success"
                :value="count.newItemsOrderCount.thisWeek"
                :label="$t('thisWeekLabel')"
              >
              <template #icon>
                <b-icon icon="arrow-up-circle" style="font-size: 5rem;"></b-icon>
               </template>
            </StatCard>
            </b-col>
            <b-col cols="col-sm-12 col-md-3 col-lg-3" class="text-center">
              <StatCard
                color="info"
                :value="count.newItemsOrderCount.thisMonth"
                :label="$t('thisMonthLabel')"
              >
              <template #icon>
                <b-icon icon="arrow-up-circle" style="font-size: 5rem;"></b-icon>
               </template>
            </StatCard>
            </b-col>
          </div>
        </section>

        <!-- Section: Sales Amount Statistics -->
        <section class="stats-section">
          <b-row class="mb-4">
            <b-col cols="12" class="text-center">
              <h2 class="section-title">
                {{ $t("salesAmountStatisticsLabel") }}
              </h2>
            </b-col>
          </b-row>
          <div class="row">
            <b-col cols="col-sm-12 col-md-3 col-lg-3" class="text-center">

            <StatCard
              color="primary"
              :value="formattedNumber(salesAmountCount.total)"
              :label="$t('totalLabel')"
            >
            <template #icon>
                <b-icon icon="arrow-up-circle" style="font-size: 5rem;"></b-icon>
               </template>
            </StatCard>
            </b-col>
            <b-col cols="col-sm-12 col-md-3 col-lg-3" class="text-center">

            <StatCard
              color="danger"
              :value="formattedNumber(salesAmountCount.thisDay)"
              :label="$t('todayLabel')"
            >
            <template #icon>
                <b-icon icon="arrow-up-circle" style="font-size: 5rem;"></b-icon>
               </template>
        </StatCard>
            </b-col>
            <b-col cols="col-sm-12 col-md-3 col-lg-3" class="text-center">

            <StatCard
              color="success"
              :value="formattedNumber(salesAmountCount.thisWeek)"
              :label="$t('thisWeekLabel')"
            >
            <template #icon>
                <b-icon icon="arrow-up-circle" style="font-size: 5rem;"></b-icon>
               </template>
        </StatCard>
            </b-col>
            <b-col cols="col-sm-12 col-md-3 col-lg-3" class="text-center">

            <StatCard
              color="info"
              :value="formattedNumber(salesAmountCount.thisMonth)"
              :label="$t('thisMonthLabel')"
            >
            <template #icon>
                <b-icon icon="arrow-up-circle" style="font-size: 5rem;"></b-icon>
               </template>
        </StatCard>
            </b-col>
          </div>
        </section>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import SidebarView from "@/components/Layout/SidebarView.vue";
import { HTTP } from "../http/api.js";
import StatCard from "@/components/StatCard.vue";

export default {
  name: "DashboardView",
  components: {
    SidebarView,
    StatCard,
  },
  data() {
    return {
      count: {
        newItemsOrderCount: {
          thisDay: 0,
          thisMonth: 0,
          thisWeek: 0,
          total: 0,
        },
        newOrderCount: {
          thisDay: 0,
          thisMonth: 0,
          thisWeek: 0,
          total: 0,
        },
      },
      salesAmountCount: {
        thisDay: 0,
        thisMonth: 0,
        thisWeek: 0,
        total: 0,
      },
      show: false,
    };
  },
  mounted() {
    this.getCount();
    this.salesCount();
  },
  methods: {
    formattedNumber(info) {
      return info.toLocaleString();
    },
    getCount() {
      this.show = true;
      HTTP.get(`Admin/GetSellsCount`)
        .then((response) => {
          this.count = response.data.data;
          this.show = false;
        })
        .catch(() => {
          this.show = false;
        });
    },
    salesCount() {
      this.show = true;
      HTTP.get(`Admin/SalesAmountCount`)
        .then((response) => {
          this.salesAmountCount = response.data.data;
          this.show = false;
        })
        .catch(() => {
          this.show = false;
        });
    },
  },
};
</script>

<style>
.stats-section {
  margin-bottom: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.section-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}
</style>
