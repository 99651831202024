<template>
    <div class="stat-card">
        <div class="stat-icon">
            <slot name="icon"></slot>
        </div>
        <div class="stat-content">
            <h3 class="stat-value">{{ value }}</h3>
            <p class="stat-label">{{ label }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "StatCard",
    props: {
        value: {
            type: [String, Number],
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
.stat-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(135deg, #033b84, #22426b);
    color: #fff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.stat-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}
.stat-icon {
    font-size: 3rem;
    margin-right: 15px;
}
.stat-content {
    text-align: right;
}
.stat-value {
    font-size: 2rem;
    font-weight: bold;
}
.stat-label {
    font-size: 1rem;
    opacity: 0.8;
}
</style>
