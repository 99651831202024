<template>
    <b-overlay :show="show" spinner-variant="danger" spinner-type="grow" spinner-large rounded="sm">

        <SidebarView />
        <b-container fluid>
            <b-row>
                <b-col sm="12" md="12" lg="12">
                    <b-row>
                        <b-col sm="12" md="12" lg="12">
                            <hr>
                            <div class="d-flex justify-content-between align-Users-center">
                                <label class="bold_text">{{ $t('all_accounts') }}</label>
                                <b-button variant="danger" class="float-right" v-b-modal.modal-addUser block>{{
                                    $t('add_account') }}</b-button>
                                <b-modal id="modal-addUser" :title="$t('add_new_account')" hide-header hide-footer>
                                    <p class="my-4">{{ $t('add_new_account') }}</p>
                                    <form @submit.prevent="addUser">
                                        <div class="form-group mb-3">
                                            <input id="inputName" v-model="addForm.name" type="text"
                                                :placeholder="$t('full_name')" required="" class="form-control text-danger">
                                        </div>
                                        <div class="form-group mb-3">
                                            <input id="inputSellingPrice" v-model="addForm.phoneNumber" type="number"
                                                :placeholder="$t('phone_number')" required=""
                                                class="form-control text-danger">
                                        </div>
                                        <div class="form-group mb-3">
                                            <input id="inputSellingPrice" v-model="addForm.password" type="texr"
                                                :placeholder="$t('password')" required="" class="form-control text-danger">
                                        </div>
                                        <div class="form-group mb-3">
                                            <input id="inputSellingPrice" v-model="addForm.username" type="text"
                                                :placeholder="$t('username')" class="form-control text-danger">
                                        </div>
                                        <div class="form-group mb-3">
                                            <!-- role -->
                                            <select class="form-control" v-model="addForm.role">
                                                <option v-if="role == 'Commercial'" value="POS">{{ $t('point_of_sale') }}
                                                </option>
                                                <option v-if="role == 'Commercial'" value="Reader">{{ $t('price_reader') }}
                                                </option>
                                                <option v-if="role == 'Admin'" value="Commercial">{{ $t('commercial') }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="d-flex justify-content-around">
                                            <b-button variant="danger" type="submit" class="w-100 m-1" block
                                                :disabled="show == true">
                                                <b-spinner small v-if="show == true"></b-spinner> {{ $t('add') }}
                                            </b-button>
                                            <b-button variant="warning" class="w-100 m-1"
                                                @click="closeModel('modal-addUser')" block>{{ $t('close') }}</b-button>
                                        </div>
                                    </form>
                                </b-modal>
                            </div>
                            <hr>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12" md="12" lg="12" class="mb-2 print_hide">
                            <form class="print_hide">
                                <b-input v-model="search.info" type="search" :placeholder="$t('search')"></b-input>
                            </form>
                        </b-col>
                        <b-col sm="12" md="3" lg="3" class="mb-2" v-for="User in Users" v-bind:key="User.id">
                            <b-card border-variant="light" :header="User.name" class="text-center">
                                <b-card-text class="bold_text">{{ $t('role') }}: {{ User.role }}</b-card-text>
                                <p>{{ $t('username') }}: {{ User.username }}</p>
                                <div class="card-footer">
                                    <b-button-group>
                                        <b-button variant="warning" @click="deleteUserModel(User.id)">{{ $t('delete')
                                        }}</b-button>
                                        <b-button variant="danger" @click="getUserInfo(User)">{{ $t('edit') }}</b-button>
                                    </b-button-group>
                                </div>
                            </b-card>
                        </b-col>
                        <!-- add pagination -->
                        <b-pagination v-model="pageNumber" :total-rows="totalUsers" :per-page="pageSize"
                            aria-controls="my-table"></b-pagination>
                    </b-row>
                </b-col>
                <b-modal id="modal-editUser" :title="$t('edit_account')" hide-header hide-footer>
                    <p class="my-4">{{ $t('edit_account') }}</p>
                    <form @submit.prevent="EditUser">
                        <div class="form-group mb-3">
                            <input id="inputName" v-model="editForm.name" type="text" :placeholder="$t('full_name')"
                                required="" class="form-control text-danger">
                        </div>
                        <div class="form-group mb-3">
                            <input id="inputSellingPrice" v-model="editForm.phoneNumber" type="number"
                                :placeholder="$t('phone_number')" required="" class="form-control text-danger">
                        </div>
                        <div class="form-group mb-3">
                            <input id="inputSellingPrice" v-model="editForm.password" type="texr"
                                :placeholder="$t('password')" class="form-control text-danger">
                        </div>
                        <div class="form-group mb-3">
                            <input id="inputSellingPrice" v-model="editForm.username" type="text"
                                :placeholder="$t('username')" class="form-control text-danger">
                        </div>
                        <div class="form-group mb-3">
                            <!-- role -->
                            <select class="form-control" v-model="editForm.role">
                                <option value="POS">{{ $t('point_of_sale') }}</option>
                                <option value="Reader">{{ $t('price_reader') }}</option>
                                <option v-if="role == 'Admin'" value="Commercial">{{ $t('commercial') }}</option>
                            </select>
                        </div>
                        <div class="d-flex justify-content-around">
                            <b-button variant="danger" type="submit" class="w-100 m-1" block :disabled="show == true">
                                <b-spinner small v-if="show == true"></b-spinner> {{ $t('edit') }} </b-button>
                            <b-button variant="warning" class="w-100 m-1" @click="closeModel('modal-editUser')" block>{{
                                $t('close') }}</b-button>
                        </div>
                    </form>
                </b-modal>
                <b-modal id="modal-delete" :title="$t('confirm_delete')" hide-header hide-footer>
                    <p class="my-4">{{ $t('confirm_delete') }}</p>
                    <div class="d-flex justify-content-around">
                        <b-button variant="danger" class="w-100 m-1" @click="closeModel('modal-delete')" block>{{
                            $t('cancel') }}</b-button>
                        <b-button variant="warning" class="w-100 m-1" @click="deleteUser('modal-delete')" block>{{
                            $t('delete') }}</b-button>
                    </div>
                </b-modal>
            </b-row>
        </b-container>

    </b-overlay>
</template>
<script>
import SidebarView from "@/components/Layout/SidebarView.vue";
import ClockVue from "@/components/ClockVue.vue";
import VueBarcode from "@chenfengyuan/vue-barcode";
import { HTTP } from '../http/api.js';
export default {
    name: "UsersView",
    components: {
        SidebarView,
        ClockVue,
        "vue-barcode": VueBarcode,

    },
    data() {
        return {
            show: false,
            search: "",
            Users: [],
            pageNumber: 1,
            totalUsers: 0,
            pageSize: 10,
            search: {
                info: "",
            },
            SearchUsers: [],
            totalCardUsers: 0,
            userInfo: {},
            editForm: {
                name: "",
                phoneNumber: "",
                username: "",
                role: "",
                id: "",
            },
            addForm: {
                name: "",
                phoneNumber: "",
                password: "",
                username: "",
                role: "",
            },
            UserId: '',
        };
    },

    watch: {

        search: {
            handler() {
                this.GetAllUsers();
            },
            deep: true,
        },

        pageNumber() {
            this.GetAllUsers();
        },
    },

    mounted() {
        this.GetAllUsers();
    },

    computed: {
        role() {
            return localStorage.getItem("role");
        },
    },

    methods: {
        deleteUserModel(id) {
            this.UserId = id;
            this.$bvModal.show("modal-delete");
        },
        getUserInfo(User) {
            this.editForm = User;
            this.editForm.password = '';
            this.$bvModal.show("modal-editUser");
        },
        addUser() {
            this.show = true;
            HTTP.post(`Admin/AddUser`, this.addForm)
                .then((response) => {
                    this.show = false;
                    this.$toast.success(this.$i18n.t('userHasbeenAddedSuccessfully'), {
                        position: "top-right",
                        timeout: 4000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        
                    });
                    this.addForm.name = '';
                    this.addForm.password = '';
                    this.addForm.phoneNumber = 0;
                    this.addForm.username = 0;
                    this.addForm.role = '';
                    this.GetAllUsers();
                    this.$bvModal.hide('modal-addUser');
                })
                .catch((error) => {
                    this.show = false;
                    this.$toast.error(this.$i18n.t('somethingWrong'), {
                        position: "top-right",
                        timeout: 4000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        
                    });
                });
        },
        EditUser() {
            this.show = true;
            HTTP.put(`Admin/UpdateUser?id=${this.editForm.id}`, this.editForm)
                .then((response) => {
                    this.show = false;
                    this.$toast.success(this.$i18n.t('userHadbeenEditSuccessfully'), {
                        position: "top-right",
                        timeout: 4000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        
                    });
                    this.GetAllUsers();
                    this.$bvModal.hide('modal-editUser');
                })
                .catch((error) => {
                    this.show = false;
                    this.$toast.error(this.$i18n.t('somethingWrong'), {
                        position: "top-right",
                        timeout: 4000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        
                    });
                });
        },

        deleteUser(modelId) {
            this.show = true;
            HTTP.delete(`Admin/DeleteUser?id=${this.UserId}`)
                .then((response) => {
                    this.show = false;
                    this.$toast.success(this.$i18n.t('userHadbeenDeleteSuccessfully'), {
                        position: "top-right",
                        timeout: 4000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        
                    });
                    this.GetAllUsers();
                    this.$bvModal.hide(modelId);

                })
                .catch((error) => {
                    this.show = false;
                    this.$toast.error(this.$i18n.t('somethingWrong'), {
                        position: "top-right",
                        timeout: 4000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        
                    });
                });
        },


        closeModel(id) {
            this.$bvModal.hide(id);
        },


        GetAllUsers() {
            this.show = true;
            HTTP.get(`Admin/GetUsers?pageNumber=${this.pageNumber - 1}&pageSize=${this.pageSize}&info=${this.search.info}`)
                .then((response) => {
                    this.Users = response.data.data.items;
                    this.totalUsers = response.data.data.totalItems;
                    this.show = false;
                })
                .catch((error) => {
                    this.show = false;
                });
        },

    },


};
</script>