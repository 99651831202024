<template>
    <div class="d-flex justify-content-center align-items-center fullBackground" @click="handelfoce()">
        <b-row>
            <div class="centered-element centered-element-text">
                <p>{{ $t('place_barcode_on_reader') }}</p>
            </div>
            <div class="centered-element centered-element-text text-color">
                <b-form-input v-model="searchCode" ref="codeNumber" class="search_Input" type="search"
                    :placeholder="$t('barcode')"></b-form-input>
                <!-- Make sure `barcode` is imported correctly as a JSON file -->
                <lottie-animation path="./barcode.json" :width="256" :height="256" />
                <p v-if="price !== ''"><span>{{ $t('price') }} </span> {{ formattedNumber(price) }} <span>{{
                    $t('currency') }}</span></p>
            </div>
        </b-row>
    </div>
</template>

<script>
import SidebarView from "@/components/Layout/SidebarView.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import { HTTP } from '../http/api.js';

export default {
    name: "DashboardView",
    components: {
        SidebarView,
        LottieAnimation,
        HTTP
    },
    data() {
        return {
            show: false,
            options: {
                minimizable: false,
                playerSize: "standard",
                backgroundColor: '#fff',
                backgroundStyle: 'color',
            },
            searchCode: '',
            price: "",
            typingTimer: null,
            doneTypingInterval: 500, // Adjust this value to set the delay before making the request
        };
    },
    mounted() {
        //  focue to input 
        this.$refs.codeNumber.focus();
    },
    watch: {
        searchCode: {
            handler() {
                clearTimeout(this.typingTimer);

                if (this.searchCode == '') {
                    return;
                }
                this.typingTimer = setTimeout(() => {
                    this.SearchByCode();
                }, this.doneTypingInterval);
            },
            deep: true,
        },
    },
    methods: {
        handelfoce() {
            this.$refs.codeNumber.focus();
        },

        formattedNumber(info) {
            return info.toLocaleString();
        },

        SearchByCode() {
            this.show = true;
            HTTP.get(`Admin/GetItemsByCode?code=${this.searchCode}`)
                .then((response) => {
                    this.show = false;
                    this.price = response.data.data.sellingPrice;
                    this.searchCode = '';
                    this.$refs.codeNumber.focus();
                })
                .catch((error) => {
                    this.show = false;
                    this.searchCode = '';
                });
        },
    },
};
</script>
