<template>
  <div>
    <b-overlay
      :show="show"
      spinner-variant="danger"
      spinner-type="grow"
      spinner-large
      rounded="sm"
    >
      <SidebarView />
      <b-container fluid>
        <b-row>
          <b-col sm="12" md="8" lg="8">
            <b-row class="align-middle mt-4">
              <b-col
                sm="12"
                md="4"
                lg="4"
                class="d-flex align-items-center justify-content-center"
              >
                <img src="../assets/logoarabic.png" alt="logo" class="w-50" />
              </b-col>
              <b-col
                sm="12"
                md="4"
                lg="4"
                class="d-flex align-items-center justify-content-center"
              >
                <p class="employeeName">
                  {{ $t("employeeLabel") }}
                  <span class="bold_text">{{ userInfo.name }}</span>
                </p>
              </b-col>
              <b-col
                sm="12"
                md="4"
                lg="4"
                class="d-flex align-items-center justify-content-center"
              >
                <ClockVue />
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="12" md="12" lg="12">
                <hr />
                <div class="d-flex justify-content-between align-items-center">
                  <b-button-group>
                    <b-button
                      v-for="tag in tags"
                      :key="tag.id"
                      :variant="
                        search.info === tag.name ? 'dark' : 'outline-dark'
                      "
                      @click="search.info = tag.name"
                    >
                      {{ tag.name }}
                    </b-button>
                    <b-button
                      :variant="search.info === '' ? 'dark' : 'outline-dark'"
                      @click="search.info = ''"
                    >
                      {{ $t("all") }}
                    </b-button>
                  </b-button-group>

                  <b-button
                    variant="danger"
                    class="float-right"
                    v-b-modal.modal-addItem
                    block
                  >
                    {{ $t("addItemButton") }}
                  </b-button>
                  <b-modal
                    id="modal-addItem"
                    :title="$t('confirmClearCartTitle')"
                    hide-header
                    hide-footer
                  >
                    <b-overlay
                      :show="show"
                      spinner-variant="danger"
                      spinner-type="grow"
                      spinner-large
                      rounded="sm"
                    >
                      <p class="my-4">{{ $t("addNewItemMessage") }}</p>

                      <form @submit.prevent="addItem">
                        <!-- add photo -->
                        <div class="text-center w-100">
                          <img
                            v-if="!imagePreview"
                            @click="getFile"
                            src="../assets/upload.png"
                            alt="upload"
                            width="180"
                          />
                        </div>
                        <input
                          type="file"
                          ref="uploadPhoto"
                          @change="uploadFile"
                          hidden
                        />

                        <div @click="getFile" class="text-center w-100">
                          <b-avatar
                            v-if="imagePreview"
                            :src="imagePreview"
                            size="9rem"
                            class="mb-3"
                          ></b-avatar>
                        </div>
                        <b-row>
                          <b-col sm="12" md="6" lg="6">
                            <div class="form-group mb-3">
                              <p class="form_label">
                                {{ $t("itemNamePlaceholder") }}
                              </p>
                              <input
                                id="inputName"
                                v-model="addForm.name"
                                type="text"
                                :placeholder="$t('itemNamePlaceholder')"
                                required=""
                                class="form-control text-danger"
                              />
                            </div>
                          </b-col>
                          <b-col sm="12" md="6" lg="6">
                            <div class="form-group mb-3">
                              <p class="form_label">
                                {{ $t("sellingPricePlaceholder") }}
                              </p>
                              <input
                                id="inputSellingPrice"
                                v-model="addForm.sellingPrice"
                                type="number"
                                :placeholder="$t('sellingPricePlaceholder')"
                                required=""
                                class="form-control text-danger"
                              />
                            </div>
                          </b-col>
                          <b-col sm="12" md="6" lg="6">
                            <div class="form-group mb-3">
                              <p class="form_label">
                                {{ $t("disCountPricePlaceholder") }}
                              </p>
                              <input
                                id="inputSellingPrice"
                                v-model="addForm.disCountPrice"
                                type="number"
                                :placeholder="$t('disCountPricePlaceholder')"
                                required=""
                                class="form-control text-danger"
                              />
                            </div>
                          </b-col>
                          <b-col sm="12" md="6" lg="6">
                            <div class="form-group mb-3">
                              <p class="form_label">
                                {{ $t("purchasingPricePlaceholder") }}
                              </p>
                              <input
                                id="inputSellingPrice"
                                v-model="addForm.purchasingPrice"
                                type="number"
                                :placeholder="$t('purchasingPricePlaceholder')"
                                required=""
                                class="form-control text-danger"
                              />
                            </div>
                          </b-col>
                        </b-row>
                        <div class="form-group mb-3">
                          <p class="form_label">
                            {{ $t("descriptionPlaceholder") }}
                          </p>
                          <input
                            id="inputSellingPrice"
                            v-model="addForm.description"
                            type="text"
                            :placeholder="$t('descriptionPlaceholder')"
                            class="form-control text-danger"
                          />
                        </div>
                        <div class="form-group mb-3">
                          <p class="form_label">{{ $t("codePlaceholder") }}</p>
                          <input
                            id="inputSellingPrice"
                            v-model="addForm.code"
                            type="text"
                            :placeholder="$t('codePlaceholder')"
                            required=""
                            class="form-control text-danger"
                          />
                        </div>
                        <div class="text-center w-100">
                          <vue-barcode
                            ref="BarImg"
                            v-if="addForm.code.toString()"
                            tag="img"
                            :value="addForm.code.toString()"
                            :options="{
                              displayValue: true,
                              lineColor: '#2B2B2C',
                            }"
                          />
                        </div>

                        <div class="form-group mb-3">
                          <div class="form-group mb-3">
                            <p class="form_label">
                              {{ $t("categoryPlaceholder") }}
                            </p>
                            <select class="form-control" v-model="addForm.tags">
                              <option v-for="item in tags" :value="item.name">
                                {{ item.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="d-flex justify-content-around">
                          <b-button
                            variant="danger"
                            type="submit"
                            class="w-100 m-1"
                            block
                            :disabled="show == true"
                          >
                            <b-spinner small v-if="show == true"></b-spinner>
                            {{ $t("addButton") }}
                          </b-button>
                          <b-button
                            variant="warning"
                            class="w-100 m-1"
                            @click="closeModel('modal-addItem')"
                            block
                          >
                            {{ $t("closeButton") }}
                          </b-button>
                        </div>
                      </form>
                    </b-overlay>
                  </b-modal>
                </div>
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" md="6" lg="6" class="mb-2">
                <form class="">
                  <b-input
                    v-model="search.info"
                    type="search"
                    :placeholder="$t('searchPlaceholder')"
                  ></b-input>
                </form>
              </b-col>
              <b-col sm="12" md="6" lg="6" class="mb-2">
                <div class="showBarcode">
                  <p>{{ $t("showBarcode") }}</p>
                  <b-form-checkbox
                    v-model="showbarCode"
                    name="check-button"
                    switch
                  >
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                sm="12"
                md="2"
                lg="2"
                class="mb-2"
                v-for="item in Items"
                :key="item.id"
              >
                <b-card
                  @click="addToCartList(item)"
                  border-variant="light"
                  :header="item.name"
                  class="product-card shadow-sm hover:shadow-lg transition-all duration-300 transform hover:scale-105 text-center"
                >
                  <!-- Discount Banner -->
                  <div
                    v-if="
                      item.disCountPrice !== 0 &&
                      item.disCountPrice !== item.sellingPrice
                    "
                    class="discount-banner bg-danger text-white px-2 py-1 rounded position-absolute top-0 end-0 m-2"
                  >
                    <span>{{ $t("discountLabel") }}</span>
                  </div>

                  <!-- Price Section -->
                  <b-card-text class="price-section mb-3">
                    <div
                      v-if="
                        item.disCountPrice !== 0 &&
                        item.disCountPrice !== item.sellingPrice
                      "
                      class="d-flex justify-content-center align-items-center"
                    >
                      <span class="discounted-price text-danger fw-bold mx-2">
                        {{ formatPrice(item.disCountPrice) }}
                        {{ $t("currency") }}
                      </span>
                      <span
                        class="original-price text-muted text-decoration-line-through"
                      >
                        {{ formatPrice(item.sellingPrice) }}
                        {{ $t("currency") }}
                      </span>
                    </div>
                    <div v-else class="regular-price fw-bold">
                      {{ formatPrice(item.sellingPrice) }} {{ $t("currency") }}
                    </div>
                  </b-card-text>

                  <!-- Barcode or Avatar -->
                  <div class="product-media mb-3">
                    <vue-barcode
                      v-if="showbarCode"
                      ref="BarImg"
                      tag="img"
                      class="barcode-image w-100 h-auto"
                      :value="item.code.toString()"
                      :options="{
                        displayValue: true,
                        lineColor: '#2B2B2C',
                        width: 2,
                        height: 100,
                      }"
                    />
                    <div v-else class="text-center">
                      <b-avatar
                        :src="item.image"
                        size="5rem"
                        icon="star-fill"
                        class="product-avatar"
                      ></b-avatar>
                    </div>
                  </div>

                  <!-- Tags -->
                  <p class="product-tags text-muted small mt-2">
                    {{ item.tags }}
                  </p>
                </b-card>
              </b-col>

              <b-pagination
                v-model="pageNumber"
                :total-rows="totalItems"
                :per-page="pageSize"
                aria-controls="my-table"
              >
              </b-pagination>
            </b-row>
          </b-col>
          <!-- colse button -->

          <b-col sm="12" md="4" lg="4" class="text-center position-sticky">
            <div>
              <b-form-group
                label-cols="12"
                :label="$t('itemCodeLabel')"
                label-align="center"
              >
                <b-form-input
                  v-model="searchCode"
                  ref="codeNumber"
                  type="search"
                  :placeholder="$t('searchPlaceholder')"
                >
                </b-form-input>
              </b-form-group>
            </div>
            <div class="card_list mt-3">
              <b-table
                striped
                hover
                :items="carditems"
                :fields="cardfields"
                responsive
              >
                <template v-slot:cell(price)="row">
                  {{
                    row.item.price != row.item.disCountPrice
                      ? row.item.disCountPrice
                      : row.item.price
                  }}
                </template>
                <template v-slot:cell(actions)="row">
                  <b-button
                    variant="danger"
                    @click="deleteItem(row.index)"
                    size="sm"
                    class="mr-2"
                  >
                    {{ $t("deleteButton") }}
                  </b-button>
                </template>
              </b-table>
            </div>
            <!-- add total  -->
            <div class="total mt-3">
              <b-card text-variant="black" class="text-center">
                <b-card-text class="card_table d-flex justify-content-around">
                  <p>
                    {{ $t("totalLabel") }}: {{ formattedNumber }}
                    {{ $t("currency") }}
                  </p>
                  <p>
                    {{ $t("countLabel") }}: {{ totalCardItems }}
                    {{ $t("itemLabel") }}
                  </p>
                </b-card-text>
              </b-card>
            </div>
            <div class="row mt-3">
              <b-col sm="12" md="8" lg="8" class="mb-2">
                <b-button
                  variant="danger"
                  class="w-100"
                  @click="addOrder(true)"
                  block
                  :disabled="totalCardItems <= 0"
                >
                  {{ $t("saveAndprintButton") }}
                </b-button>
              </b-col>
              <b-col sm="12" md="2" lg="2" class="mb-2">
                <b-button
                  variant="danger"
                  class="w-100"
                  @click="addOrder(false)"
                  block
                  :disabled="totalCardItems <= 0"
                >
                  {{ $t("save") }}
                </b-button>
              </b-col>
              <b-col sm="12" md="2" lg="2" class="mb-2">
                <b-button
                  variant="dark"
                  class="w-100"
                  v-b-modal.modal-empty
                  block
                  :disabled="totalCardItems <= 0"
                >
                  {{ $t("emptyButton") }}
                </b-button>
              </b-col>
              <b-modal
                id="modal-empty"
                :title="$t('confirmClearCartTitle')"
                hide-header
                hide-footer
              >
                <p class="my-4">{{ $t("confirmClearCartMessage") }}</p>
                <div class="d-flex justify-content-around">
                  <b-button
                    variant="danger"
                    class="w-100 m-1"
                    @click="closeModel('modal-empty')"
                    block
                  >
                    {{ $t("cancelButton") }}
                  </b-button>
                  <b-button
                    variant="warning"
                    class="w-100 m-1"
                    @click="EmptycardList('modal-empty')"
                    block
                  >
                    {{ $t("confirmButton") }}
                  </b-button>
                </div>
              </b-modal>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-sidebar id="sidebar-right" title="Sidebar" no-header right shadow>
        <div class="px-3 py-2">
          <CalculatorComp />
        </div>
      </b-sidebar>
    </b-overlay>

    <div>
      <b-col sm="12" md="4" lg="4" class="text-center print_hide" id="print">
        <div
          class="mt-3 pos-printer"
          style="border: 1px solid #ddd; padding: 15px; border-radius: 10px"
        >
          <!-- Logo Section -->
          <div class="text-center w-100" style="margin-bottom: 10px">
            <img
              src="../assets/logoarabic.png"
              alt="logo"
        
              style="width: 100px"
            />
          </div>
          <hr style="border: 1px solid #000" />

          <!-- Items Table -->
          <table
            class="pos-table"
            style="width: 100%; border-collapse: collapse; margin-bottom: 15px"
          >
            <thead>
              <tr style="background-color: #f4f4f4">
                <th
                  class="font-print"
                  style="padding: 8px; border: 1px solid #ddd"
                >
                  {{ $t("itemLabel") }}
                </th>
                <th
                  class="font-print"
                  style="padding: 8px; border: 1px solid #ddd"
                >
                  {{ $t("countLabel") }}
                </th>
                <th
                  class="font-print"
                  style="padding: 8px; border: 1px solid #ddd"
                >
                  {{ $t("price") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in carditems"
                :key="index"
                style="text-align: center"
              >
                <td
                  class="font-print"
                  style="padding: 8px; border: 1px solid #ddd"
                >
                  {{ item.name }}
                </td>
                <td
                  class="font-print"
                  style="padding: 8px; border: 1px solid #ddd"
                >
                  {{ item.quantity }}
                </td>
                <td
                  class="font-print"
                  style="padding: 8px; border: 1px solid #ddd"
                >
                  {{
                    item.price != item.disCountPrice
                      ? item.disCountPrice
                      : item.price
                  }}
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Total Section -->
          <div
            class="total mt-3 pos-total"
            style="border-top: 1px dashed #000; padding-top: 10px"
          >
            <b-card
              text-variant="black"
              class="text-center pos-card"
              style="background-color: #f9f9f9; border: none"
            >
              <b-card-text
                class="card_table d-flex justify-content-around"
                style="font-size: 14px; font-weight: bold"
              >
                <p style="margin: 0">
                  {{ $t("totalLabel") }}: {{ formattedNumber }}
                  {{ $t("currency") }}
                </p>
                <p style="margin: 0">
                  {{ $t("countLabel") }}: {{ totalCardItems }}
                  {{ $t("itemLabel") }}
                </p>
              </b-card-text>
            </b-card>
          </div>
        </div>
      </b-col>
    </div>
  </div>
</template>

<script>
import SidebarView from "@/components/Layout/SidebarView.vue";
import CalculatorComp from "@/components/CalculatorComp.vue";
import ClockVue from "@/components/ClockVue.vue";
import VueBarcode from "@chenfengyuan/vue-barcode";
import { HTTP } from "../http/api.js";
// import store from '../store/store'; // Adjust the path based on your actual folder structure

export default {
  name: "PosView",
  components: {
    SidebarView,
    ClockVue,
    "vue-barcode": VueBarcode,
    CalculatorComp,
  },
  data() {
    return {
      showbarCode: false,
      itemPhoto: "",
      show: false,
      totaPrice: 0,
      search: "",
      carditems: [],
      typingTimer: null,
      doneTypingInterval: 500,

      addForm: {
        name: "",
        description: "",
        sellingPrice: 0,
        purchasingPrice: 0,
        tags: "مواد اخرى",
        code: "",
        disCountPrice: 0,
      },

      imagePreview: "",
      showUpload: false,
      Items: [],
      tags: [],
      pageNumber: 1,
      totalItems: 0,
      pageSize: 12,
      search: {
        info: "",
      },
      searchCode: "",
      SearchItems: [],

      totalCardItems: 0,
      userInfo: {},
      orderForSend: {
        orderCode: "",
        customerOrderItem: [],
      },
    };
  },

  computed: {
    formattedNumber() {
      return this.totaPrice.toLocaleString();
    },
    cardfields() {
      const lang = this.$i18n.locale;
      if (!lang) {
        return [];
      }
      return [
        {
          key: "name",
          label: this.$i18n.t("itemLabel"),
        },
        {
          key: "quantity",
          label: this.$i18n.t("countLabel"),
        },
        {
          key: "price",
          label: this.$i18n.t("price"),
        },
        {
          key: "total",
          label: this.$i18n.t("total"),
        },
        {
          key: "actions",
          label: this.$i18n.t("actions"),
        },
      ];
    },
    posCardFields() {
      const lang = this.$i18n.locale;
      if (!lang) {
        return [];
      }
      return [
        {
          key: "name",
          label: this.$i18n.t("itemLabel"),
        },
        {
          key: "quantity",
          label: this.$i18n.t("countLabel"),
        },
        {
          key: "price",
          label: this.$i18n.t("price"),
        },
        {
          key: "total",
          label: this.$i18n.t("total"),
        },
      ];
    },
  },
  watch: {
    carditems: {
      handler() {
        this.totaPrice = 0;
        this.carditems.forEach((item) => {
          this.totaPrice += item.total;
        });
        this.totalCardItems = this.carditems.length;
      },
      deep: true,
    },
    search: {
      handler() {
        this.GetAllItems();
      },
      deep: true,
    },
    searchCode: {
      handler() {
        clearTimeout(this.typingTimer);

        if (this.searchCode == "") {
          return;
        }
        this.typingTimer = setTimeout(() => {
          this.SearchByCode();
        }, this.doneTypingInterval);
      },
      deep: true,
    },
    pageNumber() {
      this.GetAllItems();
    },

    "addForm.sellingPrice": {
      handler() {
        this.addForm.disCountPrice = this.addForm.sellingPrice;
      },
      deep: true,
    },
  },

  mounted() {
    this.getTags();
    this.$refs.codeNumber.focus();
    this.GetAllItems();
    this.addForm.code = Math.floor(Math.random() * 1000000000).toString();
    this.userInfo = JSON.parse(localStorage.getItem("info"));

    window.addEventListener("keyup", (e) => {
      if (e.ctrlKey && e.keyCode === 38) {
        this.$root.$emit("bv::toggle::collapse", "sidebar-right");
      }
    });
  },

  methods: {
    getTags() {
      HTTP.get(`Admin/GetTags?pageNumber=0&pageSize=10000`)
        .then((response) => {
          this.tags = response.data.data.items;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFile() {
      this.$refs.uploadPhoto.click();
    },

    uploadFile(event) {
      const selectedFile = event.target.files[0];
      this.itemPhoto = selectedFile;
      if (selectedFile) {
        this.imagePreview = URL.createObjectURL(selectedFile);
        this.showUpload = false;
      }
    },
    formatPrice(price) {
      if (price) {
        return price.toLocaleString("en-EG");
      }
      return "";
    },
    addOrder(isPrint) {
      const textDirection = document.documentElement.dir;
      const toastPosition = textDirection === "rtl" ? "top-right" : "top-left";

      if (this.carditems.length <= 0) {
        this.$toast.error(this.$i18n.t("emptyCartMessage"), {
          position: toastPosition,
          timeout: 4000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
        return;
      }
      this.show = true;
      this.orderForSend.orderCode = "";
      this.orderForSend.customerOrderItem = [];
      for (const item of this.carditems) {
        this.orderForSend.customerOrderItem.push({
          itemId: item.id,
          quantity: item.quantity,
        });
      }
      this.orderForSend.orderCode = Math.floor(
        Math.random() * 1000000000
      ).toString();
      HTTP.post(`Admin/AddOrder`, this.orderForSend)
        .then((response) => {
          if (response) {
            this.show = false;
            this.$toast.warning(this.$i18n.t("addOrderSucsses"), {
              position: "top-right",
              timeout: 4000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
            });
            this.carditems = [];
            this.$refs.codeNumber.focus();
            if (isPrint) {
              this.printCard();
            }
          }
        })
        .catch((error) => {
          this.show = false;
          this.$toast.error(this.$i18n.t("error"), {
            position: "top-right",
            timeout: 4000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        });
    },

    // printCard() {
    //   const prtHtml = document.getElementById("print").innerHTML;
    //   let stylesHtml = "";
    //   for (const node of [
    //     ...document.querySelectorAll('link[rel="stylesheet"], style'),
    //   ]) {
    //     stylesHtml += node.outerHTML;
    //   }
    //   const WinPrint = window.open(
    //     "",
    //     "",
    //     "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
    //   );
    //   WinPrint.document.write(`<!DOCTYPE html>
    //             <html>
    //             <head>
    //                 ${stylesHtml}
    //             </head>

    //             <body dir="rtl">
    //                 ${prtHtml}
    //             </body>
    //             </html>`);

    //   WinPrint.document.close();
    //   WinPrint.focus();
    //   WinPrint.print();
    //   WinPrint.close();
    //   this.$refs.codeNumber.focus();
    // },

    printCard() {
      const prtHtml = document.getElementById("print").innerHTML;

      // Include custom font via CSS
      const stylesHtml = `
    <style>
      body {
        font-family: font-family: 'Cairo', sans-serif;
        direction: rtl;
      }
      .pos-table th, .pos-table td {
        font-size: 12px;
      }
    </style>
  `;

      // Build print content
      const content = `
    <!DOCTYPE html>
    <html>
    <head>
      ${stylesHtml}
    </head>
    <body>
      ${prtHtml}
    </body>
    </html>
  `;

      // Create a hidden iframe for direct printing
      const iframe = document.createElement("iframe");
      iframe.style.position = "absolute";
      iframe.style.top = "-10000px";
      document.body.appendChild(iframe);

      const doc = iframe.contentWindow.document;
      doc.open();
      doc.write(content);
      doc.close();

      // Directly trigger printing
      iframe.contentWindow.focus();
      iframe.contentWindow.print();

      // Remove iframe after printing
      setTimeout(() => document.body.removeChild(iframe), 1000);
    },

    addItem() {
      this.show = true;
      var formData = new FormData();
      formData.append("Name", this.addForm.name);
      formData.append("Description", this.addForm.description);
      formData.append("SellingPrice", this.addForm.sellingPrice);
      formData.append("PurchasingPrice", this.addForm.purchasingPrice);
      formData.append("Tags", this.addForm.tags);
      formData.append("Code", this.addForm.code);
      formData.append("Image", this.itemPhoto);
      formData.append("DisCountPrice", this.addForm.disCountPrice);

      HTTP.post(`Admin/AddItem`, formData)
        .then((response) => {
          this.$toast.success(this.$i18n.t("addItemToOrderSucsses"), {
            position: "top-right",
            timeout: 4000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
          this.addForm.name = "";
          this.addForm.description = "";
          this.addForm.sellingPrice = 0;
          this.addForm.purchasingPrice = 0;
          this.addForm.code = Math.floor(
            Math.random() * 1000000000000
          ).toString();
          this.GetAllItems();
          console.log(response.data);
          var item = {
            name: response.data.data.name,
            quantity: 1,
            price: response.data.data.sellingPrice,
            disCountPrice: response.data.data.disCountPrice,
            total: response.data.data.sellingPrice * 1,
            id: response.data.data.id,
          };
          this.carditems.push(item);
          this.$bvModal.hide("modal-addItem");
        })
        .catch((error) => {
          this.show = false;
          this.$toast.error(this.$i18n.t("error"), {
            position: "top-right",
            timeout: 4000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        });
    },

    EmptycardList(id) {
      this.carditems = [];
      this.$bvModal.hide(id);
      this.$refs.codeNumber.focus();
    },
    closeModel(id) {
      this.$bvModal.hide(id);
    },
    addToCartList(item) {
      const bodyElement = document.querySelector("body");
      const textDirection = bodyElement.getAttribute("dir");
      const toastPosition = textDirection === "rtl" ? "top-right" : "top-left";
      const cartItem = {
        name: item.name,
        quantity: 1,
        price: item.sellingPrice,
        disCountPrice: item.disCountPrice,
        total:
          item.sellingPrice !== item.disCountPrice
            ? item.disCountPrice
            : item.sellingPrice,
        id: item.id,
      };

      this.carditems.push(cartItem);

      if (this.$refs.codeNumber) {
        this.$refs.codeNumber.focus();
      }

      this.$toast.success(`${cartItem.name} : ${this.$i18n.t("itemToCard")}`, {
        position: toastPosition,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },

    deleteItem(index) {
      this.carditems.splice(index, 1);
      this.$toast.error(this.$i18n.t("deleteItemFromOrderSucsses"), {
        position: "top-right",
        timeout: 4000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    GetAllItems() {
      this.show = true;
      HTTP.get(
        `Admin/GetItems?pageNumber=${this.pageNumber - 1}&pageSize=${
          this.pageSize
        }&info=${this.search.info}`
      )
        .then((response) => {
          this.Items = response.data.data.items;
          this.totalItems = response.data.data.totalItems;
          this.show = false;
        })
        .catch((error) => {
          this.show = false;
        });
    },
    SearchByCode() {
      HTTP.get(`Admin/GetItemsByCode?code=${this.searchCode}`)
        .then((response) => {
          this.SearchItems = response.data.data;
          this.$toast.success(
            `${this.SearchItems.name}` + " : " + this.$i18n.t("itemToCard"),
            {
              position: "top-right",
              timeout: 4000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
            }
          );
          this.searchCode = "";
          var item = {
            name: this.SearchItems.name,
            quantity: 1,
            price: this.SearchItems.sellingPrice,
            total: this.SearchItems.sellingPrice * 1,
            id: this.SearchItems.id,
          };
          this.carditems.push(item);
          this.$refs.codeNumber.focus();
        })
        .catch((error) => {
          this.searchCode = "";
        });
    },
  },
};
</script>

<style scoped>
.product-card {
  position: relative;
  border-radius: 0.75rem;
  overflow: hidden;
  transition: all 0.3s ease;
}

.product-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.barcode-image {
  max-height: 150px;
  object-fit: contain;
}

.product-media {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-avatar {
  border: 2px solid #f0f0f0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.discount-banner {
  z-index: 10;
  font-size: 0.8rem;
}

.price-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.discounted-price {
  color: #dc3545;
  margin-right: 0.5rem;
}

.original-price {
  text-decoration: line-through;
  color: #6c757d;
}
</style>
