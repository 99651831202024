<template>
    <b-overlay :show="show" spinner-variant="danger" spinner-type="grow" spinner-large rounded="sm">

        <SidebarView />
        <b-container fluid>
            <b-row>
                <b-col sm="12" md="12" lg="12">
                    <b-row>
                        <b-col sm="12" md="12" lg="12">
                            <hr>
                            <div class="d-flex justify-content-between align-Tagss-center">
                                <label class="bold_text">{{ $t('all_categories') }}</label>
                                <b-button variant="danger" class="float-right" v-b-modal.modal-addTags block>{{
                                    $t('add_category') }}</b-button>
                                <b-modal id="modal-addTags" :title="$t('add_new_category')" hide-header hide-footer>
                                    <p class="my-4">{{ $t('add_new_category') }}</p>
                                    <form @submit.prevent="addTags">
                                        <div class="form-group mb-3">
                                            <input id="inputName" v-model="addForm.name" type="text"
                                                :placeholder="$t('category_name')" required=""
                                                class="form-control text-danger">
                                        </div>

                                        <div class="d-flex justify-content-around">
                                            <b-button variant="danger" type="submit" class="w-100 m-1" block
                                                :disabled="show == true">
                                                <b-spinner small v-if="show == true"></b-spinner> {{ $t('add') }}
                                            </b-button>
                                            <b-button variant="warning" class="w-100 m-1"
                                                @click="closeModel('modal-addTags')" block>{{ $t('close') }}</b-button>
                                        </div>
                                    </form>
                                </b-modal>
                            </div>
                            <hr>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12" md="12" lg="12" class="mb-2 print_hide">
                            <form class="print_hide">
                                <b-input v-model="search.info" type="search" :placeholder="$t('search')"></b-input>
                            </form>
                        </b-col>
                        <b-col sm="12" md="2" lg="2" class="mb-2" v-for="Tags in Tagss" v-bind:key="Tags.id">
                            <b-card border-variant="light" :header="Tags.name" class="text-center">
                                <div class="card-footer">
                                    <b-button-group>
                                        <b-button variant="warning" @click="deleteTagsModel(Tags.id)">{{ $t('delete')
                                        }}</b-button>
                                        <b-button variant="danger" @click="getTagsInfo(Tags)">{{ $t('edit') }}</b-button>
                                    </b-button-group>
                                </div>
                            </b-card>
                        </b-col>
                        <!-- add pagination -->
                        <b-pagination v-model="pageNumber" :total-rows="totalTagss" :per-page="pageSize"
                            aria-controls="my-table"></b-pagination>
                    </b-row>
                </b-col>
                <b-modal id="modal-editTags" :title="$t('edit_account')" hide-header hide-footer>
                    <p class="my-4">{{ $t('edit_account') }}</p>
                    <form @submit.prevent="EditTags">
                        <div class="form-group mb-3">
                            <input id="inputName" v-model="editForm.name" type="text" :placeholder="$t('category_name')"
                                required="" class="form-control text-danger">
                        </div>
                        <div class="d-flex justify-content-around">
                            <b-button variant="danger" type="submit" class="w-100 m-1" block :disabled="show == true">
                                <b-spinner small v-if="show == true"></b-spinner> {{ $t('edit') }}
                            </b-button>
                            <b-button variant="warning" class="w-100 m-1" @click="closeModel('modal-editTags')" block>{{
                                $t('close') }}</b-button>
                        </div>

                    </form>
                </b-modal>
                <b-modal id="modal-delete" :title="$t('confirm_delete')" hide-header hide-footer>
                    <p class="my-4">{{ $t('confirm_delete') }}</p>
                    <div class="d-flex justify-content-around">
                        <b-button variant="danger" class="w-100 m-1" @click="closeModel('modal-delete')" block>{{
                            $t('cancel') }}</b-button>
                        <b-button variant="warning" class="w-100 m-1" @click="deleteTags('modal-delete')" block>{{
                            $t('delete') }}</b-button>
                    </div>
                </b-modal>
            </b-row>
        </b-container>

    </b-overlay>
</template>
<script>
import SidebarView from "@/components/Layout/SidebarView.vue";
import ClockVue from "@/components/ClockVue.vue";
import VueBarcode from "@chenfengyuan/vue-barcode";
import { HTTP } from '../http/api.js';
export default {
    name: "TagssView",
    components: {
        SidebarView,
        ClockVue,
        "vue-barcode": VueBarcode,

    },
    data() {
        return {
            show: false,
            search: "",
            Tagss: [],
            pageNumber: 1,
            totalTagss: 0,
            pageSize: 10,
            search: {
                info: "",
            },
            SearchTagss: [],
            totalCardTagss: 0,
            TagsInfo: {},
            editForm: {
                name: "",
                phoneNumber: "",
                Tagsname: "",
                role: "",
                id: "",
            },
            addForm: {
                name: "",
                isForAll: false,
            },
            TagsId: '',
        };
    },

    watch: {

        search: {
            handler() {
                this.GetAllTagss();
            },
            deep: true,
        },

        pageNumber() {
            this.GetAllTagss();
        },
    },

    mounted() {
        this.GetAllTagss();
    },

    computed: {
        role() {
            return localStorage.getItem("role");
        },
    },

    methods: {
        deleteTagsModel(id) {
            this.TagsId = id;
            this.$bvModal.show("modal-delete");
        },
        getTagsInfo(Tags) {
            this.editForm = Tags;
            this.$bvModal.show("modal-editTags");
        },
        addTags() {

            this.show = true;
            HTTP.post(`Admin/AddTag`, this.addForm)
                .then((response) => {
                    this.show = false;
                    this.$toast.success(this.$i18n.t('TagsHasbeenAddedSuccessfully'), {
                        position: "top-right",
                        timeout: 4000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        
                    });
                    this.addForm.name = '';
                    this.addForm.password = '';
                    this.addForm.phoneNumber = 0;
                    this.addForm.Tagsname = 0;
                    this.addForm.role = '';
                    this.GetAllTagss();
                    this.$bvModal.hide('modal-addTags');
                })
                .catch((error) => {
                    this.show = false;
                    this.$toast.error(this.$i18n.t('somethingWrong'), {
                        position: "top-right",
                        timeout: 4000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        
                    });
                });
        },
        EditTags() {
            this.show = true;
            HTTP.put(`Admin/UpdateTag?id=${this.editForm.id}`, this.editForm)
                .then((response) => {
                    this.show = false;
                    this.$toast.success(this.$i18n.t('TagsHadbeenEditSuccessfully'), {
                        position: "top-right",
                        timeout: 4000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        
                    });
                    this.GetAllTagss();
                    this.$bvModal.hide('modal-editTags');
                })
                .catch((error) => {
                    this.show = false;
                    this.$toast.error(this.$i18n.t('somethingWrong'), {
                        position: "top-right",
                        timeout: 4000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        
                    });
                });
        },

        deleteTags(modelId) {
            this.show = true;
            HTTP.delete(`Admin/DeleteTag?id=${this.TagsId}`)
                .then((response) => {
                    this.show = false;
                    this.$toast.success(this.$i18n.t('TagsHadbeenDeleteSuccessfully'), {
                        position: "top-right",
                        timeout: 4000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        
                    });
                    this.GetAllTagss();
                    this.$bvModal.hide(modelId);

                })
                .catch((error) => {
                    this.show = false;
                    this.$toast.error(this.$i18n.t('somethingWrong'), {
                        position: "top-right",
                        timeout: 4000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        
                    });
                });
        },


        closeModel(id) {
            this.$bvModal.hide(id);
        },


        GetAllTagss() {
            this.show = true;
            HTTP.get(`Admin/GetTags?pageNumber=${this.pageNumber - 1}&pageSize=${this.pageSize}&info=${this.search.info}`)
                .then((response) => {
                    this.Tagss = response.data.data.items;
                    this.totalTagss = response.data.data.totalItems;
                    this.show = false;
                })
                .catch((error) => {
                    this.show = false;
                });
        },

    },


};
</script>