import axios from "axios";

export const HTTP = axios.create({
    baseURL: `https://pos-api.tatwer.tech/`,
});

// إضافة Interceptor لتحديث Authorization Header
HTTP.interceptors.request.use(config => {
    const token = localStorage.getItem("token"); // جلب التوكن الجديد
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});
