<template>
  <div class="d-flex justify-content-center align-items-center fullBackgroundHome">
    <b-container>
      <!-- add logo -->
      <b-row class="justify-content-center mt-4">
        <b-col lg="6" sm="12">
          <div class="text-center">
            <img src="../assets/logoarabic.png" alt="" class="logo-img w-50" />
          </div>
        </b-col>
      </b-row>

      <div class="language-selector">
        <label for="language-select" class="language-label">{{ $t('select_language') }}:</label>
        <select id="language-select" v-model="$i18n.locale" @change="changeLanguage" class="language-dropdown">
          <option value="en">
            <flag iso="us" /> English
          </option>
          <option value="ar">
            <flag iso="iq" /> عربي
          </option>
        </select>
      </div>
      <b-row class="justify-content-between align-items-center">
        <b-col lg="6" sm="12">
          <div>
            <h1 class="display-6 fw-semibold text-capitalize mb-3 lh-base">

              <h1>{{ $t('hello') }}</h1>
              <span class="text-danger">Chashir</span>

            </h1>
            <p class="lead text-muted lh-base mb-4">
              {{ $t('homePageDes') }}
            </p>
            <div class="cashire-panel-filter">
              <b-row>
                <b-col md="6">
                  <b-button class="btn-warning submit-btn w-100 h-100 mb-2" @click="goToLogin()">
                    <i class="ri-search-2-line align-bottom me-1"></i> {{ $t('login') }}
                  </b-button>
                </b-col>
                <b-col md="6">
                  <b-button class="btn-danger submit-btn w-100 h-100 mb-2" @click="goToRegisterRoute()">
                    <i class="ri-search-2-line align-bottom me-1"></i> {{ $t('register') }}
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <!-- end col -->
        <b-col lg="6" sm="12">

          <img src="../assets/chashir.png" alt="" class="chashir-img" />

        </b-col>
      </b-row>
      <!-- add footer -->
      <b-row class="justify-content-center mt-4">
        <b-col lg="6" sm="12">
          <div class="text-center">
            <p>{{ $t('contactSupport') }}</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>


<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  data() {
    return {
      number: '',
    }
  },
  methods: {
    goToRegisterRoute() {
      this.$router.push('/register');
    },
    goToLogin() {
      this.$router.push('/login');
    },
    changeLanguage(obj) {
      localStorage.setItem('language', obj.target.value);
      const currentLang = this.$i18n.locale;
      document.body.dir = currentLang === 'en' ? 'ltr' : 'rtl';
    }
  }

}

</script>
<style scoped></style>
