<template>
    <b-overlay :show="show" spinner-variant="danger" spinner-type="grow" spinner-large rounded="sm">

        <div class="container-fluid">
            <div class="row no-gutter">
                <div class="col-md-6 d-none d-md-flex bg-image"></div>
                <div class="col-md-6 bg-light">
                    <div class="login d-flex align-items-center py-5">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-10 col-xl-7 mx-auto">
                                    <h3 class="display-4">{{ $t('registerTitle') }}</h3>
                                    <p class="text-muted mb-4">{{ $t('welcomeMessage') }}</p>
                                    <form @submit.prevent="register">
                                        <div class="form-group mb-3">
                                            <input :id="'inputName'" v-model="form.name" type="text" minlength="8"
                                                :placeholder="$t('fullNamePlaceholder')" required="" autofocus="true"
                                                class="form-control text-danger">
                                        </div>
                                        <div class="form-group mb-3">
                                            <input :id="'inputNumber'" v-model="form.phoneNumber" type="number"
                                                pattern="07\d{9}" minlength="11" :placeholder="$t('phoneNumberPlaceholder')"
                                                required="" autofocus="" class="form-control text-danger">
                                        </div>
                                        <div class="form-group mb-3">
                                            <input :id="'inputPassword'" v-model="form.password" minlength="8"
                                                type="password" :placeholder="$t('passwordPlaceholder')" required=""
                                                class="form-control text-danger">
                                        </div>
                                        <b-button type="submit" variant="danger">{{ $t('registerButton') }}</b-button>
                                        <div class="text-right mt-4">
                                            {{ $t('alreadyHaveAccountMessage') }}
                                            <router-link :to="'/login'" class="text-danger bold_text">{{ $t('loginLink')
                                            }}</router-link>
                                        </div>
                                        <div class="text-right mt-4">
                                            <p>{{ $t('developedBy') }}
                                                <a :href="$t('companyWebsite')" class="">
                                                    <span class="text-danger bold_text">{{ $t('companyName') }}</span>
                                                </a>
                                            </p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div><!-- End -->
                    </div>
                </div><!-- End -->
            </div>
        </div>
    </b-overlay>
</template>
<script>
import { HTTP } from '../../http/api.js';

export default {
    name: 'RegisterView',
    data() {
        return {
            show: false,
            form: {
                phoneNumber: '',
                password: '',
                name: '',
                username: 'formWeb',
                role: 'user'
            }
        };
    },

    methods: {
        register() {
            this.show = true;
            HTTP.post('Auth/RegisterUser', this.form)
                .then(response => {
                    this.show = false;
                    this.$toast.success(this.$i18n.t('sucessRegister'), {
                        position: "top-right",
                        timeout: 5000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        
                    });
                    this.$router.push('/login');
                })
                .catch(error => {
                    let message = error.response.data.message;
                    this.$toast.error(message, {
                        position: "top-right",
                        timeout: 5000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        
                    });

                    this.show = false;

                });
        }
    }

}
</script>
