<template>
    <b-overlay :show="show" spinner-variant="danger" spinner-type="grow" spinner-large rounded="sm">

        <div class="container-fluid">
            <div class="row no-gutter">
                <div class="col-md-6 d-none d-md-flex bg-image"></div>
                <div class="col-md-6 bg-light">
                    <div class="login d-flex align-items-center py-5">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-10 col-xl-7 mx-auto">
                                    <h3 class="display-4">{{ $t('loginTitle') }}</h3>
                                    <p class="text-muted mb-4">{{ $t('welcomeMessage') }}</p>
                                    <form @submit.prevent="login">
                                        <div class="form-group mb-3">
                                            <input id="inputNumber" v-model="form.phoneNumber" type="number"
                                                :placeholder="$t('phoneNumberPlaceholder')" required="" autofocus="true"
                                                class="form-control text-danger">
                                        </div>
                                        <div class="form-group mb-3">
                                            <input id="inputPassword" v-model="form.password" minlength="8" type="password"
                                                :placeholder="$t('passwordPlaceholder')" required=""
                                                class="form-control text-danger">
                                        </div>
                                        <b-button type="submit" variant="danger">{{ $t('loginButton') }}</b-button>
                                        <div class="text-right mt-4">
                                            {{ $t('noAccountMessage') }}
                                            <router-link to="/register" class="text-danger bold_text">{{ $t('registerLink')
                                            }}</router-link>
                                        </div>
                                        <div class="text-right mt-4">
                                            <p>{{ $t('developedBy') }}
                                                <a :href="$t('companyWebsite')" class="">
                                                    <span class="text-danger bold_text">{{ $t('companyName') }}</span>
                                                </a>
                                            </p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div><!-- End -->
                    </div>
                </div><!-- End -->
            </div>
        </div>
    </b-overlay>
</template>
<script>
import { HTTP } from '../../http/api.js';

export default {
    name: 'LoginView',
    data() {
        return {
            show: false,
            form: {
                phoneNumber: '',
                password: ''
            }
        };
    },

    methods: {
        login() {


            this.show = true;
            HTTP.post('Auth/Login', this.form)
                .then(response => {
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('role', response.data.role);
                    if (response.data.role == 'Admin') {
                        this.$router.push('/users');
                    } else if (response.data.role == 'POS') {
                        this.$router.push('/pos');
                    } else if (response.data.role == 'Reader') {
                        this.$router.push('/priceReader');
                    } else {
                        this.$router.push('/dashboard');
                    }

                    this.show = false;

                    localStorage.setItem('info', JSON.stringify(response.data.info))
                })
                .catch(error => {
                    this.show = false;

                    this.$toast.error(this.$i18n.t('errorInLoginInfo'), {
                        position: "top-right",
                        timeout: 5000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        
                    });


                });
        }
    }

}
</script>
