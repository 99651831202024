<template>
  <b-overlay
    :show="show"
    spinner-variant="danger"
    spinner-type="grow"
    spinner-large
    rounded="sm"
  >
    <SidebarView />
    <b-container fluid>
      <b-row>
        <b-col sm="12" md="12" lg="12">
          <b-row>
            <b-col sm="12" md="12" lg="12">
              <hr />
              <div class="d-flex justify-content-between align-items-center">
                <label class="bold_text">{{ $t("allItemsLabel") }}</label>
                <b-button
                  variant="danger"
                  class="float-right"
                  v-b-modal.modal-addItem
                  block
                >
                  {{ $t("addItemLabel") }}
                </b-button>
                <b-modal
                  id="modal-addItem"
                  :title="$t('addItemModalTitle')"
                  hide-header
                  hide-footer
                >
                  <b-overlay
                    :show="show"
                    spinner-variant="danger"
                    spinner-type="grow"
                    spinner-large
                    rounded="sm"
                  >
                    <p class="my-4">{{ $t("addItemModalTitle") }}</p>
                    <form @submit.prevent="addItem">
                      <div class="text-center w-100">
                        <img
                          v-if="!imagePreview"
                          @click="getFile"
                          src="../assets/upload.png"
                          alt="upload"
                          width="180"
                        />
                      </div>

                      <input
                        type="file"
                        ref="uploadPhoto"
                        @change="uploadFile"
                        hidden
                      />
                      <div @click="getFile" class="text-center w-100">
                        <b-avatar
                          v-if="imagePreview"
                          :src="imagePreview"
                          size="9rem"
                          class="mb-3"
                        ></b-avatar>
                      </div>

                      <b-row>
                        <b-col sm="12" md="6" lg="6">
                          <div class="form-group mb-3">
                            <p class="form_label">
                              {{ $t("itemNamePlaceholder") }}
                            </p>
                            <input
                              id="inputName"
                              v-model="addForm.name"
                              type="text"
                              :placeholder="$t('itemNamePlaceholder')"
                              required=""
                              class="form-control text-danger"
                            />
                          </div>
                        </b-col>
                        <b-col sm="12" md="6" lg="6">
                          <div class="form-group mb-3">
                            <p class="form_label">
                              {{ $t("sellingPricePlaceholder") }}
                            </p>
                            <input
                              id="inputSellingPrice"
                              v-model="addForm.sellingPrice"
                              type="number"
                              :placeholder="$t('sellingPricePlaceholder')"
                              required=""
                              class="form-control text-danger"
                            />
                          </div>
                        </b-col>
                        <b-col sm="12" md="6" lg="6">
                          <div class="form-group mb-3">
                            <p class="form_label">
                              {{ $t("disCountPricePlaceholder") }}
                            </p>
                            <input
                              id="inputSellingPrice"
                              v-model="addForm.disCountPrice"
                              type="number"
                              :placeholder="$t('disCountPricePlaceholder')"
                              required=""
                              class="form-control text-danger"
                            />
                          </div>
                        </b-col>
                        <b-col sm="12" md="6" lg="6">
                          <div class="form-group mb-3">
                            <p class="form_label">
                              {{ $t("purchasingPricePlaceholder") }}
                            </p>
                            <input
                              id="inputSellingPrice"
                              v-model="addForm.purchasingPrice"
                              type="number"
                              :placeholder="$t('purchasingPricePlaceholder')"
                              required=""
                              class="form-control text-danger"
                            />
                          </div>
                        </b-col>
                        <b-col sm="12" md="12" lg="12">
                          <div class="form-group mb-3">
                            <p class="form_label">
                              {{ $t("descriptionPlaceholder") }}
                            </p>
                            <input
                              id="inputSellingPrice"
                              v-model="addForm.description"
                              type="text"
                              :placeholder="$t('descriptionPlaceholder')"
                              class="form-control text-danger"
                            />
                          </div>
                        </b-col>
                      </b-row>

                      <div class="form-group mb-3">
                        <p class="form_label">{{ $t("codePlaceholder") }}</p>
                        <input
                          id="inputSellingPrice"
                          v-model="addForm.code"
                          type="text"
                          :placeholder="$t('codePlaceholder')"
                          required=""
                          class="form-control text-danger"
                        />
                      </div>
                      <div class="text-center w-100">
                        <vue-barcode
                          ref="BarImg"
                          v-if="addForm.code.toString()"
                          tag="img"
                          :value="addForm.code.toString()"
                          :options="{
                            displayValue: true,
                            lineColor: '#2B2B2C',
                          }"
                        />
                      </div>

                      <div class="form-group mb-3">
                        <div class="form-group mb-3">
                          <p class="form_label">
                            {{ $t("categoryPlaceholder") }}
                          </p>
                          <select class="form-control" v-model="addForm.tags">
                            <option v-for="item in tags" :value="item.name">
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="d-flex justify-content-around">
                        <b-button
                          variant="danger"
                          type="submit"
                          class="w-100 m-1"
                          block
                          :disabled="show == true"
                        >
                          <b-spinner small v-if="show == true"></b-spinner>
                          {{ $t("addButton") }}
                        </b-button>
                        <b-button
                          variant="warning"
                          class="w-100 m-1"
                          @click="closeModel('modal-addItem')"
                          block
                        >
                          {{ $t("closeButton") }}
                        </b-button>
                      </div>
                    </form>
                  </b-overlay>
                </b-modal>
              </div>
              <hr />
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="12" class="mb-2">
              <form class="">
                <b-input
                  v-model="search.info"
                  type="search"
                  :placeholder="$t('searchPlaceholder')"
                ></b-input>
              </form>
            </b-col>
            <b-col
              sm="12"
              md="2"
              lg="2"
              class="mb-2"
              v-for="item in Items"
              :key="item.id"
            >
              <b-card
                border-variant="light"
                :header="item.name"
                class="text-center"
              >
                <b-card-text class="bold_text">
                  {{ $t("itemPriceLabel") }}:
                  {{ formatPrice(item.sellingPrice) }} {{ $t("currency") }}
                </b-card-text>
                <div class="text-center w-100">
                  <b-avatar
                    :src="item.image"
                    size="10rem"
                    icon="star-fill"
                  ></b-avatar>
                </div>
                <p>{{ item.tags }}</p>
                <div class="card-footer">
                  <b-button-group>
                    <b-button
                      variant="warning"
                      @click="deleteItemModel(item.id)"
                    >
                      {{ $t("deleteButtonLabel") }}
                    </b-button>
                    <b-button
                      variant="warning"
                      @click="printListOfCode(item, 30)"
                    >
                      {{ $t("printCodeButtonLabel") }}
                    </b-button>
                    <b-button variant="danger" @click="getItemInfo(item)">
                      {{ $t("editButtonLabel") }}
                    </b-button>
                  </b-button-group>
                </div>
              </b-card>
            </b-col>
            <!-- add pagination -->
            <b-pagination
              v-model="pageNumber"
              :total-rows="totalItems"
              :per-page="pageSize"
              aria-controls="my-table"
            ></b-pagination>
          </b-row>
        </b-col>
        <!-- other modals here -->
      </b-row>
      <b-modal
        id="modal-editItem"
        :title="$t('editItemModalTitle')"
        hide-header
        hide-footer
      >
        <b-overlay
          :show="show"
          spinner-variant="danger"
          spinner-type="grow"
          spinner-large
          rounded="sm"
        >
          <p class="my-4">{{ $t("editItemButtonLabel") }}</p>
          <form @submit.prevent="EditItem">
            <!-- add photo -->
            <div @click="getFile" class="text-center w-100">
              <b-avatar
                v-if="!imagePreview"
                src="https://placekitten.com/300/300"
                size="9rem"
                class="mb-3"
              ></b-avatar>
            </div>

            <input type="file" ref="uploadPhoto" @change="uploadFile" hidden />
            <div @click="getFile" class="text-center w-100">
              <b-avatar
                v-if="imagePreview"
                :src="imagePreview"
                size="9rem"
                class="mb-3"
              ></b-avatar>
            </div>
            <b-row>
              <b-col sm="12" md="6" lg="6">
                <div class="form-group mb-3">
                  <p class="form_label">{{ $t("itemNamePlaceholder") }}</p>
                  <input
                    id="inputName"
                    v-model="editForm.name"
                    type="text"
                    :placeholder="$t('itemNamePlaceholder')"
                    required=""
                    class="form-control text-danger"
                  />
                </div>
              </b-col>
              <b-col sm="12" md="6" lg="6">
                <div class="form-group mb-3">
                  <p class="form_label">{{ $t("sellingPricePlaceholder") }}</p>
                  <input
                    id="inputSellingPrice"
                    v-model="editForm.sellingPrice"
                    type="number"
                    :placeholder="$t('sellingPricePlaceholder')"
                    required=""
                    class="form-control text-danger"
                  />
                </div>
              </b-col>
              <b-col sm="12" md="6" lg="6">
                <div class="form-group mb-3">
                  <p class="form_label">{{ $t("disCountPricePlaceholder") }}</p>
                  <input
                    id="inputSellingPrice"
                    v-model="editForm.disCountPrice"
                    type="number"
                    :placeholder="$t('disCountPricePlaceholder')"
                    required=""
                    class="form-control text-danger"
                  />
                </div>
              </b-col>
              <b-col sm="12" md="6" lg="6">
                <div class="form-group mb-3">
                  <p class="form_label">
                    {{ $t("purchasingPricePlaceholder") }}
                  </p>
                  <input
                    id="inputSellingPrice"
                    v-model="editForm.purchasingPrice"
                    type="number"
                    :placeholder="$t('purchasingPricePlaceholder')"
                    required=""
                    class="form-control text-danger"
                  />
                </div>
              </b-col>
            </b-row>

            <div class="form-group mb-3">
              <p class="form_label">{{ $t("descriptionPlaceholder") }}</p>
              <input
                id="inputSellingPrice"
                v-model="editForm.description"
                type="text"
                :placeholder="$t('descriptionPlaceholder')"
                class="form-control text-danger"
              />
            </div>
            <div class="form-group mb-3">
              <p class="form_label">{{ $t("codePlaceholder") }}</p>
              <input
                id="inputSellingPrice"
                v-model="editForm.code"
                type="text"
                :placeholder="$t('codePlaceholder')"
                required=""
                class="form-control text-danger"
              />
            </div>
            <div class="text-center w-100">
              <vue-barcode
                ref="BarImg"
                v-if="editForm.code.toString()"
                tag="img"
                :value="addForm.code.toString()"
                :options="{ displayValue: true, lineColor: '#2B2B2C' }"
              />
            </div>

            <div class="form-group mb-3">
              <p class="form_label">{{ $t("categoryPlaceholder") }}</p>
              <select class="form-control" v-model="editForm.tags">
                <option v-for="item in tags" :value="item.name">
                  {{ item.name }}
                </option>
              </select>
            </div>

            <div class="d-flex justify-content-around">
              <b-button
                variant="danger"
                type="submit"
                class="w-100 m-1"
                block
                :disabled="show == true"
              >
                <b-spinner small v-if="show == true"></b-spinner>
                {{ $t("edit") }}
              </b-button>
              <b-button
                variant="warning"
                class="w-100 m-1"
                @click="closeModel('modal-editItem')"
                block
              >
                {{ $t("closeButton") }}
              </b-button>
            </div>
          </form>
        </b-overlay>
      </b-modal>
      <b-modal
        id="modal-delete"
        :title="$t('deleteConfirmationModalTitle')"
        hide-header
        hide-footer
      >
        <p class="my-4">{{ $t("deleteConfirmationMessage") }}</p>
        <div class="d-flex justify-content-around">
          <b-button
            variant="danger"
            class="w-100 m-1"
            @click="closeModel('modal-delete')"
            block
            >{{ $t("cancelButtonLabel") }}</b-button
          >
          <b-button
            variant="warning"
            class="w-100 m-1"
            @click="deleteItem('modal-delete')"
            block
            >{{ $t("deleteButtonLabel") }}</b-button
          >
        </div>
      </b-modal>
      <div id="printMe" class="text-align-center">
        <b-row>
          <b-col
            class="text-align-center"
            sm="3"
            md="3"
            lg="3"
            v-for="item in barCodeList"
            :key="item.code"
          >
            <vue-barcode
              ref="BarImg"
              v-if="item.code.toString()"
              tag="img"
              :value="item.code.toString()"
              :options="{ displayValue: true, lineColor: '#2B2B2C' }"
            />
            <p style="text-align: center; width: 100%">{{ item.name }}</p>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </b-overlay>
</template>
<script>
import SidebarView from "@/components/Layout/SidebarView.vue";
import ClockVue from "@/components/ClockVue.vue";
import VueBarcode from "@chenfengyuan/vue-barcode";

import { HTTP } from "../http/api.js";
export default {
  name: "ItemsView",
  components: {
    SidebarView,
    ClockVue,
    "vue-barcode": VueBarcode,
  },
  data() {
    return {
      selected: null,
      options: ["list", "of", "options"],
      show: false,
      search: "",
      Items: [],
      pageNumber: 1,
      totalItems: 0,
      pageSize: 12,
      search: {
        info: "",
      },
      SearchItems: [],
      totalCardItems: 0,
      userInfo: {},
      editForm: {
        name: "",
        description: "",
        sellingPrice: 0,
        purchasingPrice: 0,
        tags: "مواد اخرى",
        code: "",
        id: "",
      },
      imagePreview: "",
      showUpload: false,
      addForm: {
        name: "",
        description: "",
        sellingPrice: 0,
        purchasingPrice: 0,
        disCountPrice : 0,
        tags: "مواد اخرى",
        code: "",
      },
      barCodeList: [],
      itemId: "",
      tags: [],
    };
  },

  watch: {
    search: {
      handler() {
        this.GetAllItems();
      },
      deep: true,
    },

    pageNumber() {
      this.GetAllItems();
    },
    
    // if disCountPrice 0 make it equal to sellingPrice
    "addForm.sellingPrice": {
      handler() {
          this.addForm.disCountPrice = this.addForm.sellingPrice;
      },
      deep: true,
    },

    
  },

  mounted() {
    this.getTags();
    this.GetAllItems();
    this.addForm.code = Math.floor(Math.random() * 1000000000).toString();
    this.userInfo = JSON.parse(localStorage.getItem("info"));
  },

  methods: {
    getTags() {
      HTTP.get(`Admin/GetTags?pageNumber=0&pageSize=10000`)
        .then((response) => {
          this.tags = response.data.data.items;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getFile() {
      this.$refs.uploadPhoto.click();
    },

    uploadFile(event) {
      const selectedFile = event.target.files[0];
      this.itemPhoto = selectedFile;
      if (selectedFile) {
        this.imagePreview = URL.createObjectURL(selectedFile);
        this.showUpload = false;
      }
    },

    printListOfCode(code, count) {
      this.barCodeList = [];
      for (let index = 0; index < count; index++) {
        this.barCodeList.push({ code: code.code, name: code.name });
      }
      this.$nextTick(() => {
        this.print();
      });
    },
    print() {
      const printContents = document.getElementById("printMe").innerHTML;
      const printWindow = window.open("", "_blank");
      const originalHead = document.head.innerHTML;

      // Create the content for the new window
      const newContent = `
    <html>
      <head>
        ${originalHead}
      </head>
      <body dir="rtl">
        ${printContents}
      </body>
    </html>
  `;

      printWindow.document.open();
      printWindow.document.write(newContent);
      printWindow.document.close();

      // Wait for the window to load its content before printing
      printWindow.onload = () => {
        printWindow.print();
        printWindow.close();
      };
    },

    deleteItemModel(id) {
      this.itemId = id;
      this.$bvModal.show("modal-delete");
    },
    getItemInfo(item) {
      this.itemPhoto = null;
      this.editForm = item;
      this.imagePreview = item.image;
      this.$bvModal.show("modal-editItem");
    },
    addItem() {
      this.show = true;
      var formData = new FormData();
      formData.append("Name", this.addForm.name);
      formData.append("Description", this.addForm.description);
      formData.append("SellingPrice", this.addForm.sellingPrice);
      formData.append("PurchasingPrice", this.addForm.purchasingPrice);
      formData.append("Tags", this.addForm.tags);
      formData.append("Code", this.addForm.code);
      formData.append("Image", this.itemPhoto);
      formData.append("DisCountPrice", this.addForm.disCountPrice);

      HTTP.post(`Admin/AddItem`, formData)
        .then((response) => {
          this.$toast.success(this.$i18n.t("addItemToOrderSucsses"), {
            position: "top-right",
            timeout: 4000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
          this.addForm.name = "";
          this.addForm.description = "";
          this.addForm.sellingPrice = 0;
          this.addForm.purchasingPrice = 0;
          this.addForm.code = Math.floor(
            Math.random() * 1000000000000
          ).toString();
          this.GetAllItems();
          console.log(response.data);
          var item = {
            name: response.data.data.name,
            quantity: 1,
            price: response.data.data.sellingPrice,
            total: response.data.data.sellingPrice * 1,
            id: response.data.data.id,
          };
          this.carditems.push(item);
          this.$bvModal.hide("modal-addItem");
        })
        .catch((error) => {
          this.show = false;
          this.$toast.error(this.$i18n.t("error"), {
            position: "top-right",
            timeout: 4000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        });
    },

    EditItem() {
      var formData = new FormData();
      formData.append("Name", this.editForm.name);
      formData.append("Description", this.editForm.description);
      formData.append("SellingPrice", this.editForm.sellingPrice);
      formData.append("PurchasingPrice", this.editForm.purchasingPrice);
      formData.append("Tags", this.editForm.tags);
      formData.append("Code", this.editForm.code);
      formData.append("Image", this.itemPhoto);
      formData.append("DisCountPrice", this.editForm.disCountPrice);

      this.show = true;
      HTTP.put(`Admin/UpdateItem?id=${this.editForm.id}`, formData)
        .then((response) => {
          this.show = false;
          this.$toast.success(this.$i18n.t("itemHadbeenEditSuccessfully"), {
            position: "top-right",
            timeout: 4000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
          this.GetAllItems();
          this.$bvModal.hide("modal-editItem");
        })
        .catch((error) => {
          this.show = false;
          this.$toast.error(this.$i18n.t("somethingWrong"), {
            position: "top-right",
            timeout: 4000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        });
    },

    deleteItem(modelId) {
      this.show = true;
      HTTP.delete(`Admin/DeleteItem?id=${this.itemId}`)
        .then((response) => {
          this.show = false;
          this.$toast.success(this.$i18n.t("somethingWrong"), {
            position: "top-right",
            timeout: 4000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
          this.GetAllItems();
          this.$bvModal.hide(modelId);
        })
        .catch((error) => {
          this.show = false;
          this.$toast.error(this.$i18n.t("somethingWrong"), {
            position: "top-right",
            timeout: 4000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        });
    },

    formatPrice(price) {
      if (price) {
        return price.toLocaleString("en-EG"); // Use the "ar-EG" locale for Arabic formatting
      }
      return "";
    },
    closeModel(id) {
      this.$bvModal.hide(id);
    },

    GetAllItems() {
      this.show = true;
      HTTP.get(
        `Admin/GetItems?pageNumber=${this.pageNumber - 1}&pageSize=${
          this.pageSize
        }&info=${this.search.info}`
      )
        .then((response) => {
          this.Items = response.data.data.items;
          this.totalItems = response.data.data.totalItems;
          this.show = false;
        })
        .catch((error) => {
          this.show = false;
        });
    },
  },
};
</script>
