import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/Auth/LoginView.vue'
import DashboardView from '../views/DashboardView.vue'
import ItemsView from '../views/ItemsView.vue'
import UsersView from '../views/UsersView.vue'
import CategoryView from '../views/CategoryView.vue'
import ReporstView from '../views/ReporstView.vue'
import PosView from '../views/PosView.vue'
import PriceReaderView from '../views/PriceReaderView.vue'
import RegisterView from '../views/Auth/RegisterView.vue'
import { i18n } from '../main'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/pos',
    name: 'pos',
    component: PosView,
    meta: {
      requiresAuth: true,
      roles: ['Commercial', 'POS']
    }
  },
  {
    path: '/items',
    name: 'items',
    component: ItemsView,
    meta: {
      requiresAuth: true,
      roles: ['Commercial', 'POS']
    }
  },
  {
    path: '/priceReader',
    name: 'priceReader',
    component: PriceReaderView,
    meta: {
      requiresAuth: true,
      roles: ['Commercial', 'POS', 'Reader']
    }

  },
  {
    path: '/reports',
    name: 'reports',
    component: ReporstView,
    meta: {
      requiresAuth: true,
      roles: ['Commercial']
    }
  },
  {
    path: '/users',
    name: 'users',
    component: UsersView,
    meta: {
      requiresAuth: true,
      roles: ['Commercial', 'Admin']
    }
  },
  {
    path: '/category',
    name: 'category',
    component: CategoryView,
    meta: {
      requiresAuth: true,
      roles: ['Commercial', 'Admin']
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: {
      requiresAuth: true,
      roles: ['Commercial', 'Admin']
    }
  },
  {
    path: '/logout',
    name: 'logout',
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  }
]

const router = new VueRouter({
  // add mode: 'history' to remove # from url
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {

  i18n.locale = localStorage.getItem('language') || 'en'


  if (to.meta.requiresAuth == false) {
    return next()
  }
  if (to.path == '/logout') {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    return next('/login')
  }
  if (to.meta.requiresAuth == true && localStorage.getItem('token') == null) {
    return next('/login')
  }
  if (to.meta.roles.includes(localStorage.getItem('role')) && localStorage.getItem('token') != null && to.meta.requiresAuth == true) {
    return next()
  }




})


export default router
