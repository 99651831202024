


<template>
    <b-navbar toggleable type="dark" variant="primary" class="shadow-lg py-2">
      <b-navbar-brand href="#" class="d-flex align-items-center">
        <img
          src="../../assets/logoarabicdark.png"
          alt="logo"
          class="logo mr-2"
          width="150px"
        />
        <span class="font-weight-bold text-white"></span>
      </b-navbar-brand>
  
      <b-navbar-toggle target="navbar-toggle-collapse" class="border-0">
        <template #default="{ expanded }">
          <b-icon v-if="expanded" icon="chevron-bar-up" class="text-white"></b-icon>
          <b-icon v-else icon="chevron-bar-down" class="text-white"></b-icon>
        </template>
      </b-navbar-toggle>
  
      <b-collapse id="navbar-toggle-collapse" is-nav>
        <b-row class="ml-auto">
          <b-col cols="6" md="3" lg="2" v-for="item in navItems" :key="item.name" class="mb-3">
            <b-card class="text-center h-100 d-flex flex-column align-items-center justify-content-center card-nav" style="height: 120px;">
              <router-link :to="item.link" class="text-decoration-none">
                <b-icon :icon="item.icon" class="text-primary" style="font-size: 2rem;"></b-icon>
                <p class="mt-2 font-weight-bold text-dark">{{ item.label }}</p>
              </router-link>
            </b-card>
          </b-col>
        </b-row>
  
        <div class="language-selector-nav d-flex align-items-center ml-md-3 mt-2 mt-md-0">
          <!-- <label for="language-select" class="text-white font-weight-bold mb-0 mr-2">
            {{ $t('select_language') }}:
          </label> -->
          <select
            id="language-select"
            v-model="$i18n.locale"
            @change="changeLanguage"
            class="form-control form-control-sm"
          >
            <option value="en">
              🇺🇸 English
            </option>
            <option value="ar">
              🇶🇪 عربي
            </option>
          </select>
        </div>
      </b-collapse>
    </b-navbar>
  </template>
  
  <script>
  export default {
    name: "SidebarView",
    data() {
      return {
        navItems: [
          { name: "dashboard", label: this.$t("home"), link: "/dashboard", icon: "house-door" },
          { name: "pos", label: this.$t("PointOfSale"), link: "/pos", icon: "cash-stack" },
          { name: "category", label: this.$t("itemTagsPlaceholder"), link: "/category", icon: "list" },
          { name: "items", label: this.$t("Items"), link: "/items", icon: "list" },
          { name: "users", label: this.$t("Accounts"), link: "/users", icon: "people" },
          { name: "reports", label: this.$t("Reports"), link: "/reports", icon: "file-earmark-bar-graph" },
          { name: "priceReader", label: this.$t("PriceReader"), link: "/priceReader", icon: "currency-dollar" },
          { name: "logout", label: this.$t("Logout"), link: "/logout", icon: "box-arrow-right", class: "text-danger" },
        ],
      };
    },
    computed: {
      role() {
        return localStorage.getItem("role");
      },
    },
    methods: {
      changeLanguage(event) {
        const lang = event.target.value;
        localStorage.setItem("language", lang);
        this.$i18n.locale = lang;
        document.body.dir = lang === "en" ? "ltr" : "rtl";
      },
    },
  };
  </script>
  
  <style scoped>
  .logo {
    border-radius: 8px;
  }
  .language-selector-nav {
    display: flex;
    align-items: center;
  }
  .nav-link {
    color: #ffffff !important;
    font-weight: 500;
    transition: color 0.3s;
  }
  .nav-link:hover {
    color: #f8d7da !important;
  }
  .b-card {
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  .b-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  }
  p {
    margin: 0;
    font-size: 1rem;
  }
  </style>